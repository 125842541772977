import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 120px;
  // background:yellow;
  display: flex;
  align-items: bottom;
  flex-direction: row;
  justify-content: bottom;
  height: 3em;
  font-weight: bold;
`;

const StyledButton = styled.button`
  color: black;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 1em;
  padding: 0.125em 0 0.125em 0;
  text-decoration: none;
  margin-top: auto;
  transition: padding 0.3s ease-in-out;
  border: unset;
  background: none;
  border-bottom: 1px solid black;

  font-family: "Pitch Sans";

  &:active {
    opacity: 0.5;
  }

  &:hover {
    padding: 0.125em 0 0.5em 0;
    margin-bottom: 0;

    .arrow {
      margin-left: 1.25em;
    }
  }
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function Contact() {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <Seo title="RSVP" />

      <h1>RSVP</h1>
      <form
        name="rsvp"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <p>
          <label className="radio-button" htmlFor="yesAttending">
            <input
              type="radio"
              id="yesAttending"
              value="yes"
              name="attending"
              className="radio_input"
              onChange={handleChange}
              required
            />
            <div class="styled-radio"></div>
            Attending
          </label>

          <label className="radio-button" htmlFor="notAttending">
            <input
              type="radio"
              id="notAttending"
              value="no"
              onChange={handleChange}
              name="attending"
              className="radio_input"
            />
            <div className="styled-radio"></div>
            Regrets
          </label>
        </p>
        <p>
          <label>
            Name:
            <br />
            <input type="text" name="name" onChange={handleChange} required />
          </label>
        </p>

        <p>
          <label>
            Email:
            <br />
            <input type="email" name="email" onChange={handleChange} />
          </label>
        </p>

        <h3 style={{ marginTop: "4em" }}>
          Which events will you be attending?
        </h3>

        <div className="day">
          <p>
            <label className="checkbox" htmlFor="friday">
              <input
                type="checkbox"
                id="friday"
                name="friday"
                value="Welcome Dinner"
                onChange={handleChange}
                className="checkbox__input"
              />
              <div className="checkbox__box"></div>
              <div className="box__description">
                <strong>Friday 10/01</strong> Welcome party
                <br />
                <span>
                  For our traveling guests. <em>Casual.</em>
                </span>
              </div>
            </label>
            <br />
            <div className="attendees">
              <label htmlFor="total-adults-friday">
                # of adults <br />
                <input
                  type="number"
                  min="0"
                  onChange={handleChange}
                  name="total-adults-friday"
                />
              </label>

              <label htmlFor="total-children-friday">
                # of children <br />
                <input
                  type="number"
                  min="0"
                  onChange={handleChange}
                  name="total-children-friday"
                />
              </label>
            </div>
          </p>
        </div>

        <div className="day">
          <p>
            <label className="checkbox" htmlFor="saturday">
              <input
                type="checkbox"
                id="saturday"
                name="saturday"
                value="Ceremony"
                onChange={handleChange}
                className="checkbox__input"
              />
              <div className="checkbox__box"></div>
              <div className="box__description">
                <strong>Saturday 10/02</strong> Ceremony and Reception
                <br />
                <span>
                  <em>Cocktail attire.</em>
                </span>
              </div>
            </label>
            <br />
            <div className="attendees">
              <label htmlFor="total-adults-saturday">
                # of adults <br />
                <input
                  type="number"
                  min="0"
                  onChange={handleChange}
                  name="total-adults-saturday"
                />
              </label>

              <label htmlFor="total-children-saturday">
                # of children <br />
                <input
                  type="number"
                  min="0"
                  onChange={handleChange}
                  name="total-children-saturday"
                />
              </label>
            </div>
          </p>
        </div>

        <div className="day">
          <p>
            <label className="checkbox" htmlFor="sunday">
              <input
                type="checkbox"
                id="sunday"
                name="sunday"
                value="Bagels in the park"
                onChange={handleChange}
                className="checkbox__input"
              />
              <div className="checkbox__box"></div>
              <div className="box__description">
                <strong>Sunday 10/03</strong> Farewell brunch <br />
                for all in Golden Gate Park. <em>Casual.</em>
              </div>
            </label>
            <br />
            <div className="attendees">
              <label htmlFor="total-adults-sunday">
                # of adults <br />
                <input
                  type="number"
                  min="0"
                  onChange={handleChange}
                  name="total-adults-sunday"
                />
              </label>

              <label htmlFor="total-children-sunday">
                # of children <br />
                <input
                  type="number"
                  min="0"
                  onChange={handleChange}
                  name="total-children-sunday"
                />
              </label>
            </div>
          </p>
        </div>

        <h3>Vaccination Status</h3>
        <p>
          A new city ordinance requires us to collect documentation of
          vaccination in advance. Stay tuned for an email about different ways
          to provide proper documentation.
        </p>
        <p>
          <label className="radio-button" htmlFor="yesVaccinated">
            <input
              type="radio"
              id="yesVaccinated"
              value="yes"
              name="vaccinated"
              className="radio_input"
              onChange={handleChange}
            />
            <div className="styled-radio"></div>
            Adults are vaccinated
          </label>
          <br />
          <label className="radio-button" htmlFor="noVaccinated">
            {" "}
            <input
              type="radio"
              id="noVaccinated"
              value="no"
              className="radio_input"
              onChange={handleChange}
              name="vaccinated"
            />
            <div className="styled-radio"></div>
            Adults are not vaccinated
          </label>
        </p>
        <div className="restrictions">
          <p>
            <label>
              Dietary Restrictions:
              <br />
              <textarea name="diet" onChange={handleChange} />
            </label>
          </p>
        </div>

        <ButtonContainer>
          <StyledButton type="submit">Submit</StyledButton>
        </ButtonContainer>
      </form>
    </Layout>
  );
}
